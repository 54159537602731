/* eslint-disable no-param-reassign, func-names */

import { isMobile } from '../../../utils';

function hoverNavigationLinks(navigationCursor, nav, el, isVisible) {
  nav.forEach((link) => {
    if (link.parentNode.classList.contains('is-hovered')) {
      link.parentNode.classList.remove('is-hovered');
    }
  });
  el.parentNode.classList.add('is-hovered');
  const { left } = el.getBoundingClientRect();
  navigationCursor.style.left = `${left}px`;
  navigationCursor.style.top = '38px';
  navigationCursor.style.opacity = isVisible ? 1 : 0;
  navigationCursor.style.transform = 'translate(-22px, 0)';
}

window.addEventListener('load', () => {
  const navigationCursor = document.querySelector('.header__menu__arrow');
  const nav = Array.prototype.slice.call(document.querySelectorAll('.menu-list__item__link'));

  if (document.querySelector('.header .header__menu') && !isMobile) {
    nav.forEach((link) => {
      link.addEventListener('mouseenter', function () {
        hoverNavigationLinks(navigationCursor, nav, this, true);
      });

      link.addEventListener('mouseleave', function () {
        hoverNavigationLinks(navigationCursor, nav, this, false);
      });
    });

    hoverNavigationLinks(navigationCursor, nav, nav[0], false);
  }
});
