/**
 *
 * @param anchorSelector
 * @param activeClass
 * @param itemClass - items witch potentially could have activeClass
 */
const addActiveLinkClass = ({
  anchorSelector = 'a',
  activeClass = 'active',
  itemsClass,
}) => {
  const anchors = document.querySelectorAll(anchorSelector);
  if (!anchors) return;

  const current = window.location.pathname;

  for (let i = 0; i < anchors.length; i += 1) {
    if (anchors[i].getAttribute('href') === current) {
      if (itemsClass) {
        const items = document.querySelectorAll(itemsClass);
        items[i].classList.add(activeClass);
      } else {
        anchors[i].classList.add(activeClass);
      }
    }
  }
};

export default addActiveLinkClass;
