/* eslint-disable no-param-reassign */
const wrapInner = (parent, wrapper) => {
  if (typeof wrapper === 'string') wrapper = document.createElement(wrapper);

  parent.appendChild(wrapper);

  while (parent.firstChild !== wrapper) wrapper.appendChild(parent.firstChild);
};

export default wrapInner;
