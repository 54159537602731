import './hoverNavigation';
import '../../../utils/customEvent';

import './style.scss';

function ToggleNav() {
  const header = document.querySelector('header');
  const button = header.querySelector('.header__burger');
  const nav = header.querySelector('.header__menu');
  const userProfileBtn = document.querySelector('.menu-list__btn--user');

  if (!header || !button || !nav) return;

  this.state = {
    isOpen: false,
  };
  this.sel = nav;
  const showEvent = new CustomEvent('show');

  this.show = () => {
    if (this.state.isOpen) return;

    this.state.isOpen = true;
    header.classList.add('header--open');
    this.sel.dispatchEvent(showEvent);

    setTimeout(() => {
      userProfileBtn.classList.add('menu-list__btn--visible');
      nav.classList.add('header__menu--visible');
    }, 1000);
  };

  this.hide = () => {
    if (!this.state.isOpen) return;

    this.state.isOpen = false;
    userProfileBtn.classList.remove('menu-list__btn--visible');
    nav.classList.remove('header__menu--visible');
    header.classList.remove('header--open');

    nav.style.height = '';
  };

  button.addEventListener(
    'click',
    () => {
      if (this.state.isOpen) {
        this.hide();
      } else {
        this.show();
      }
    },
    false,
  );

  window.addEventListener('orientationchange', this.hide);
}

window.addEventListener('DOMContentLoaded', () => {
  const toggleNav = new ToggleNav();

  toggleNav.sel.addEventListener('show', () => {});
});
